import {
    createApp,
    ref,
    computed,
    reactive,
    onMounted,
    onUnmounted,
    nextTick,
    watch
} from 'https://unpkg.com/vue@3.5.11/dist/vue.esm-browser.js';  // 來取代cdn方式
import * as XLSX from 'xlsx';

createApp({
    setup() {
        const points = ref(0);
        const smsSubject = ref("");
        const smsContent = ref("");
        const isLoading = ref(false);

        const writeList = ref("listsByExcel");
        // 發送時程
        const sendNow = ref(true);
        const sendBySchedule = ref(false);
        const selectedDatetime = ref("");
        const schedule = ref(false);
        const rowsToAdd = ref(5);
        const parsedData = ref([]);

        // helper Functions
        const generateRandomId = (prefix) =>
            `${prefix}-${Math.floor(Math.random() * 1000)}`;

        // 時間轉換
        const formatToCustomDate = (dateString) => {
            const dateObject = new Date(dateString);
            if (isNaN(dateObject.getTime())) {
                console.log("Invalis date string:", dateString);
                return "";
            }
            return [
                dateObject.getFullYear(),
                String(dateObject.getMonth() + 1).padStart(2, "0"),
                String(dateObject.getDate()).padStart(2, "0"),
                String(dateObject.getHours()).padStart(2, "0"),
                String(dateObject.getMinutes()).padStart(2, "0"),
            ].join("");
        };

        /**
         * 延遲執行
         *
         * @param func
         * @param delay
         * @returns {(function(...[*]): void)|*}
         */
        const debounce = (func, delay) => {
            let timeoutId;
            return (...args) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    func(...args);
                }, delay);
            };
        };

        const smsCount = computed(() => {
            const length = smsContent.value.length;
            return Math.ceil(length / 70);
        });

        const saveState = reactive({
            commonMessages: [
                {
                    id: generateRandomId("2"),
                    title: "say hi",
                    text: "Hello, Vue 3!",
                    isEditing: false,
                },
                {
                    id: generateRandomId("2"),
                    title: "問候語",
                    text: "你好嗎???????",
                    isEditing: false,
                },
                {
                    id: generateRandomId("2"),
                    title: "等我",
                    text: "等等一下",
                    isEditing: false,
                },
            ],
            signatures: [
                {
                    id: generateRandomId("2"),
                    text: "王曉明",
                    isEditing: false,
                },
                {
                    id: generateRandomId("2"),
                    text: "陳先生",
                    isEditing: false,
                },
            ],
            parsedData: parsedData,
        });

        const insertPlaceholder = (placeholder) => {
            smsContent.value += placeholder;
        };

        const isCollapse = reactive({
            1: false,
            2: false,
        });
        const toggleCollapse = (index) => {
            isCollapse[index] = !isCollapse[index];
        };
        const setActiveTab = (tabId) => {
            writeList.value = tabId;
        };

        const updateItemState = (type, action, id) => {
            const findId = saveState[type].findIndex((item) => item.id === id);
            switch (action) {
                case "addItem":
                    saveState[type].push({
                        title: smsSubject.value,
                        text: smsContent.value,
                        isEditing: false,
                    });
                    smsSubject.value = "";
                    smsContent.value = "";
                    break;
                case "editItem":
                    if (findId !== -1) {
                        saveState[type][findId].isEditing =
                            !saveState[type][findId].isEditing;
                    }
                    break;
                case "deleteItem":
                    if (findId !== -1) {
                        saveState[type].splice(findId, 1);
                    }
                    break;
                case "selectItem":
                    if (findId !== -1) {
                        if (type === "commonMessages") {
                            smsContent.value = saveState[type][findId].text;
                        } else {
                            smsContent.value += saveState[type][findId].text;
                        }
                    }
                    break;
            }
        };

        // 手動填寫收件者名單
        function handleAddBlankRows() {
            addRowsToParsedData(this.rowsToAdd);
        }

        const addRowsToParsedData = (rowsToAdd = 5) => {
            const newRows = Array.from({length: rowsToAdd});
            newRows.forEach(() => {
                parsedData.value.push({
                    id: generateRandomId("0"),
                    name: "",
                    phone: "",
                    job: "",
                    isEditing: false,
                });
            });
        };

        /**
         * 載入大量名單的導入流程
         */
            // 上傳大量簡訊
        const uploadFile = ref(null);
        // 計算欄寬
        const calculateColWidths = (data) => {
            const maxLengths = data[0].map((_, colIndex) =>
                Math.max(...data.map((row) => (row[colIndex] || "").toString().length))
            );
            return maxLengths.map((len) => ({wch: Math.max(len, 10)}));
        };
        const letterExample = () => {
            const emptySheetData = [["姓名", "手機門號", "職稱"]];
            const exampleSheetData = [
                ["姓名", "手機門號", "職稱"],
                ["王小明", "0912345678", "經理"],
                ["李小華", "0922333444", "副經理"],
            ];
            const exampleSheet = XLSX.utils.aoa_to_sheet(exampleSheetData);
            const emptySheet = XLSX.utils.aoa_to_sheet(emptySheetData);

            emptySheet["!cols"] = calculateColWidths(emptySheetData);
            exampleSheet["!cols"] = calculateColWidths(exampleSheetData);

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, emptySheet, "發送清單");
            XLSX.utils.book_append_sheet(workbook, exampleSheet, "大量發送範例檔");

            const excelBuffer = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });

            const blob = new Blob([excelBuffer], {
                type: "application/octet-stream",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "大量發送範例檔.xlsx";
            link.click();
        };
        // 解析 excel
        const lastUploadedFile = ref(null);
        const handleFileUpload = (event) => {
            const fileInput = event.target;
            const file = event.target.files[0];
            isLoading.value = true;
            console.log("event", event.target);

            if (
                lastUploadedFile.value &&
                lastUploadedFile.value.name === file.name &&
                lastUploadedFile.value.lastModified === file.lastModified &&
                lastUploadedFile.value.size === file.size
            ) {
                Swal.fire({
                    title: "Oops...",
                    text: "您已上傳相同的檔案，請選擇其他檔案！",
                    icon: "error",
                });
                isLoading.value = false;
                return;
            }

            lastUploadedFile.value = {
                name: file.name,
                lastModified: file.lastModified,
                size: file.size,
            };

            if (file) {
                console.log("file", file);
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, {type: "array"});
                    console.log("workbook", workbook);

                    const firstSheetName = workbook.SheetNames[0];
                    console.log("firstSheetName", firstSheetName);

                    if (firstSheetName !== "發送清單") {
                        console.log("發送清單");
                        Swal.fire({
                            title: "Oops...",
                            text: "資料格式不正確，請下載大量發送範例檔!",
                            icon: "error",
                        }).then(() => {
                            isLoading.value = false;
                            fileInput.value = "";
                        });
                        return;
                    }
                    const worksheet = workbook.Sheets[firstSheetName];
                    console.log("worksheet", worksheet);
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
                    console.log("jsonData", jsonData);
                    parseData(jsonData);

                    fileInput.value = "";
                    setTimeout(() => {
                        isLoading.value = false;
                    }, 500);
                };
                reader.readAsArrayBuffer(file);
            } else {
                isLoading.value = false;
            }
        };
        // 大量簡訊的 excel 去掉 header
        const parseData = (data) => {
            console.log("data", data);
            const headers = data[0];
            console.log("headers", headers);
            const rows = data.slice(1).map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header.trim()] = row[index] ? row[index].trim() : "";
                });
                console.log("obj", obj);
                return obj;
            });
            console.log("rows", rows);
            parsedData.value.splice(0, parsedData.value.length, ...rows);
            console.log("parsedData", parsedData.value);
            setTimeout(() => {
                isLoading.value = false;
                setActiveTab("listsByManual");
            }, 1000);
        };

        /**
         * 常用聯絡人的導入流程
         */
        const listsByCommonlyUsed = ref([
            {
                id: generateRandomId("1"),
                name: "王小明",
                phone: "0912345678",
                title: "經理",
                isSelected: false,
            },
            {
                id: generateRandomId("1"),
                name: "李小華",
                phone: "0922333444",
                title: "副經理",
                isSelected: false,
            },
            {
                id: generateRandomId("1"),
                name: "張三",
                phone: "0933221122",
                title: "主管",
                isSelected: false,
            },
        ]);
        let selectedCommonlyUsed = ref([]);
        let searchKeyword = ref("");
        const filteredList = ref([]);
        const timeoutId = ref(null);

        /**
         * 搜尋聯絡人
         */
        const performFilterList = () => {
            console.log(searchKeyword.value);
            if (!searchKeyword.value.trim()) {
                // 如果搜尋關鍵字為空，顯示全部資料
                filteredList.value = [...listsByCommonlyUsed.value];
            } else {
                // 根據關鍵字進行過濾
                filteredList.value = listsByCommonlyUsed.value.filter((item) => {
                    console.log(
                        "item.name.includes(searchKeyword.value)",
                        item.name.includes(searchKeyword.value)
                    );
                    return (
                        item.name.includes(searchKeyword.value) ||
                        item.phone.includes(searchKeyword.value) ||
                        item.title.includes(searchKeyword.value)
                    );
                });
            }
        };
        const handleFilterList = debounce(performFilterList, 300);
        const handleCommonlyUsedData = () => {
            resetGroupListData();

            selectedCommonlyUsed.value = listsByCommonlyUsed.value.filter(
                (item) => item.isSelected === true
            );
            console.log("selectedCommonlyUsed", selectedCommonlyUsed.value);
            console.log(
                "selectedCommonlyUsed.length",
                selectedCommonlyUsed.value.length
            );

            parsedData.value = [];
            selectedCommonlyUsed.value.forEach((contact) => {
                parsedData.value.push({
                    id: generateRandomId("3"),
                    name: contact.name,
                    phone: contact.phone,
                    job: contact.title,
                    isEditing: false,
                });
            });
            console.log(parsedData.value);

            console.log(
                "selectedCommonlyUsed.length2",
                selectedCommonlyUsed.value.length
            );
        };
        const resetCommonlyUsedData = () => {
            selectedCommonlyUsed.value = [];
            listsByCommonlyUsed.value.forEach((contact) => {
                console.log("resetCommonlyUsedData", contact.id, contact.isSelected);
                contact.isSelected = false;
            });
        };
        const resetGroupListData = () => {
            selectedGroupList.value = [];
            listsByGroupData.value.forEach((contact) => {
                console.log("resetGroupListData", contact.id, contact.isSelected);
                contact.isSelected = false;
            });
        };

        /**
         * 聯絡人(群組)選取的導入流程
         * */
        const listsByGroup = ref();
        const groupNames = ["AAA", "BBB", "CCC", "DDD"];
        const listsByGroupData = ref(generateRandomData());
        const filterListsByGroup = ref([]);
        const searchGroupListKeyword = ref("");
        const selectedGroup = ref("");
        let selectedGroupList = ref([]);

        const handleGroupListData = () => {
            resetCommonlyUsedData();

            selectedGroupList.value = listsByGroupData.value.filter(
                (item) => item.isSelected === true
            );
            console.log("selectedGroupList", selectedGroupList.value);
            console.log("selectedGroupList.length", selectedGroupList.value.length);

            parsedData.value = [];
            selectedGroupList.value.forEach((contact) => {
                parsedData.value.push({
                    id: generateRandomId("4"),
                    name: contact.name,
                    phone: contact.phone,
                    job: contact.title,
                    isEditing: false,
                });
            });
            console.log(parsedData.value);

            console.log("selectedGroupList.length2", selectedGroupList.value.length);
        };
        const performFilterGroupList = () => {
            const keyword = searchGroupListKeyword.value.trim().toLowerCase(); // 確保關鍵字存在並轉為小寫

            let filteredData = listsByGroupData.value;

            if (selectedGroup.value) {
                filteredData = filteredData.filter(
                    (item) => item.group === selectedGroup.value
                );
            }

            if (keyword) {
                filteredData = filteredData.filter((item) => {
                    return (
                        (item.name?.toLowerCase() || "").includes(keyword) ||
                        (item.phone?.toLowerCase() || "").includes(keyword) ||
                        (item.title?.toLowerCase() || "").includes(keyword)
                    );
                });
            }
            filterListsByGroup.value = filteredData;
        };
        const handleFilterGroupList = debounce(performFilterGroupList, 300);
        const showAllGroup = () => {
            selectedGroup.value = "";
            filterListsByGroup.value = [...listsByGroupData.value];
        };
        const showGroup = (group) => {
            selectedGroup.value = group;
            filterListsByGroup.value = listsByGroupData.value.filter(
                (item) => item.group === group
            );
        };

        // 生成隨機的(群組)用戶資料
        function generateRandomData() {
            const names = ["王小明", "李小華", "張三", "陳四", "趙五"];
            const jobTitles = ["經理", "副經理", "主管", "助理", "員工"];
            const phones = [
                "0912345678",
                "0922333444",
                "0933221122",
                "0944333444",
                "0955445566",
            ];
            const groups = ["AAA", "BBB", "CCC", "DDD"];

            return Array.from({length: 8}, (_, index) => ({
                id: generateRandomId("5"),
                name: names[Math.floor(Math.random() * names.length)],
                phone: phones[Math.floor(Math.random() * phones.length)],
                jobTitle: jobTitles[Math.floor(Math.random() * jobTitles.length)],
                group: groups[Math.floor(Math.random() * groups.length)], // 隨機加入 group
                isSelected: false,
            }));
        }

        const handleNextStep = () => {
            if (writeList.value !== "listsByManual") {
                writeList.value = "listsByManual";
                setActiveTab("listsByManual");
                return;
            }

            if (!smsContent.value) {
                Swal.fire({
                    title: "Oops...",
                    text: "您還尚未填寫發送簡訊內容，請先填寫簡訊內容！",
                    icon: "error",
                });
                return;
            }

            const smsSendData = generateSmsSendData.value;

            console.log("parsedData.value", parsedData.value);

            if (!validateParsedData()) return;

            // 第一步：確認發送資料
            Swal.fire({
                title: "請確定簡訊發送資料",
                html: `
                <p>
                  共發送 ${smsSendData.calculation.totalMessages} 通，花費點數 ${smsSendData.calculation.totalCost} 點，剩餘點數 198 點
                </p>
              `,
                showCancelButton: true,
                confirmButtonText: '下一步 <i class="fa fa-chevron-right"></i>',
                cancelButtonText: "取消",
            }).then((res) => {
                if (res.isConfirmed) {
                    // 第二步：發送設定成功
                    Swal.fire({
                        title: "簡訊發送設定成功",
                        html: `
              <p>
              共發送 ${smsSendData.calculation.totalMessages} 通，花費點數 ${smsSendData.calculation.totalCost} 點，剩餘點數 198 點
              </p>
              <p>詳細發送紀錄請至 "發送查詢" !</p>
              `,
                        icon: "success",
                        confirmButtonText: "確定",
                    });
                    resetCommonlyUsedData();
                    resetGroupListData();
                    parsedData.value = [];
                    smsSubject.value = "";
                    smsContent.value = "";
                    writeList.value = "listsByExcel";
                }
            });

            console.log("smsSendData", smsSendData);
        };
        const mapParseData = (data) => {
            return data.map((item) => ({
                id: item.id,
                name: item["name"] || "",
                phone: item["phone"] || "",
                email: item["email"] || "",
                title: item["job"] || "",
                params: {
                    custom1: item["customize1"] || "",
                    custom2: item["customize2"] || "",
                    custom3: item["customize3"] || "",
                },
            }));
        };
        const generateSmsSendData = computed(() => {
            const costPerMessage = smsCount.value;
            const totalMessages = parsedData.value.length;
            const totalCost = totalMessages * smsCount.value;
            console.log("parseData", parsedData.value.length);
            console.log("總共筆數", totalMessages, "總共扣點", totalCost);
            return {
                parseData: mapParseData(parsedData.value),
                smsSubject: smsSubject.value,
                smsContent: smsContent.value,
                calculation: {
                    totalMessages,
                    totalCost,
                    costPerMessage,
                },
                schedule: {
                    sendTime: formatToCustomDate(
                        schedule.value ? selectedDatetime.value : new Date().toISOString()
                    ),
                    immediate: schedule.value ? false : true,
                },
            };
        });

        const handleScheduleChange = () => {
            console.log(selectedDatetime.value);
            console.log(schedule.value);
            if (!schedule.value) {
                selectedDatetime.value = ""; // 清空日期
            }
        };

        // 手機檢查
        const validatePhoneNum = (phone) => /^09\d{8}$/.test(phone);
        const validateParsedData = () => {
            if (!parsedData.value || parsedData.value.length === 0) {
                // if (parseData.value.length === 0) {
                Swal.fire({
                    title: "Oops...",
                    text: "您還尚未填寫收件者名單，請先填寫收件者名單！",
                    icon: "error",
                });
                return false;
            }

            for (let i = 0; i < parsedData.value.length; i++) {
                const item = parsedData.value[i];
                if (!validatePhoneNum(item.phone)) {
                    Swal.fire({
                        title: "Oops...",
                        text: "請輸入正確的手機號碼！",
                        icon: "error",
                    });
                    return false;
                }
            }
            return true;
        };

        onMounted(() => {
            filteredList.value = [...listsByCommonlyUsed.value];
            addRowsToParsedData(5);
        });

        watch(searchKeyword, (newKeyword) => {
            if (!newKeyword.trim()) {
                clearTimeout(timeoutId.value);
                timeoutId.value = setTimeout(() => {
                    filteredList.value = [...listsByCommonlyUsed.value];
                }, 2000);
            }
        });
        const getEmployeePoints = () => {
            // return fetch(`/points/get_employee_points`, {
            //     method: "POST",
            //     headers: {'X-Requested-With': 'XMLHttpRequest'},
            //     body: new URLSearchParams({
            //         employee_id: this.employeeId
            //     })
            //
            // }).then(res => {
            //     if (!res.ok)
            //         return Promise.reject(res)
            //     return res.json()
            // });
        }

        return {
            points,
            smsSubject,
            smsContent,
            isLoading,
            rowsToAdd,
            smsCount,
            insertPlaceholder,
            isCollapse,
            toggleCollapse,
            ...saveState,
            updateItemState,
            writeList,
            uploadFile,
            handleAddBlankRows,
            letterExample,
            handleFileUpload,
            selectedCommonlyUsed,
            handleCommonlyUsedData,
            parsedData,
            listsByCommonlyUsed,
            searchKeyword,
            handleFilterList,
            filteredList,
            setActiveTab,
            handleNextStep,
            schedule,
            selectedDatetime,
            handleScheduleChange,

            // 群組
            listsByGroup,
            groupNames,
            listsByGroupData,
            filterListsByGroup,
            searchGroupListKeyword,
            handleFilterGroupList,
            showAllGroup,
            showGroup,
            selectedGroupList,
            handleGroupListData,
        };
    },
}).mount("#sms");
